<template>
  <div ref="pageBodyBoxRef" class="PageBodyBox">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const pageBodyBoxRef = ref<HTMLElement>();

const { x, y } = useScroll(pageBodyBoxRef);

const scrollPosX = ref(0);
const scrollPosY = ref(0);

watch([x, y], () => {
  scrollPosX.value = x.value;
  scrollPosY.value = y.value;
});

onActivated(() => {
  x.value = scrollPosX.value;
  y.value = scrollPosY.value;
});
</script>

<style scoped lang="scss">
.PageBodyBox {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: auto;
}
.PageBodyBox::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
.PageBodyBox::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow:
    inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.PageBodyBox::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.PageBodyBox::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
